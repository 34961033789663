<template>
   <section class="cont">
      <!-- 面包屑 -->
      <el-row class="crumbs-box">
         <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>OMS</el-breadcrumb-item>
            <el-breadcrumb-item>智能问答</el-breadcrumb-item>
            <el-breadcrumb-item>问答管理</el-breadcrumb-item>
         </el-breadcrumb>
      </el-row>
      <!-- 主体内容 -->
      <el-row class="cont-main">
         <el-col :span="4">
            <el-row class="top-title">
               <span>问题分类</span>
               <i @click="isInput = true, name = ''" class="el-icon-plus"></i>
            </el-row>
            <el-row class="btm-tree">
               <el-row class="input-box" v-show="isInput">
                  <el-input v-model="name" @blur="saveCategory" ></el-input>
               </el-row>
               <el-row class="block">
                  <el-tree
                          ref="singleTable"
                          :data="treeData"
                          :props="defaultProps"
                          node-key="id"
                          highlight-current
                          @current-change="changeCategory"
                          :expand-on-click-node="false"
                          lazy
                          :load="getCategories">
                  <span class="custom-tree-node" slot-scope="{ node, data }" @mouseover="id = data.id" @mouseout="id = ''">
                    <span>{{ data.name }}</span>
                    <span v-show="data.id === id ? true : false">
                       <el-popover
                               placement="top-end"
                               width="200"
                               trigger="click">
                         <el-input  v-model="name" @blur="saveCategory"></el-input>
                         <el-button slot="reference" type="text"  icon="el-icon-plus" @click="name = ''"></el-button>
                       </el-popover>
                       <el-popover
                               placement="top-end"
                               width="200"
                               trigger="click">
                         <el-input  v-model="name" @blur="saveCategory('edit')"></el-input>
                         <el-button slot="reference" type="text"  icon="el-icon-edit" @click="name = data.name"></el-button>
                       </el-popover>
                      <el-button type="text"  @click="() => delCategory(node, data)" icon="el-icon-delete"></el-button>
                    </span>
                  </span>
                  </el-tree>
               </el-row>
            </el-row>
         </el-col>
         <el-col :span="20">
            <el-row>
               <el-col :span="11" style="padding: 0 10px">
                  <el-table
                          ref="desTable"
                          :data="desData"
                          highlight-current-row
                          @current-change="updateDescribe"
                          style="width: 100%;">
                     <el-table-column prop="text" label="问题描述" width="80"></el-table-column>
                     <el-table-column align="right">
                        <template slot="header" slot-scope="scope">
                           <el-row class="des-header">
                              <el-button v-show="showPlus" @click="appendDescribe" class="plus-btn" icon="el-icon-plus"  plain></el-button>
                              <el-input v-model="paramData" @keyup.enter.native="getDescribes(scope)"  placeholder="搜索问题"/>
                           </el-row>
                        </template>
                        <template slot-scope="scope">
                           <i class="el-icon-delete" @click="delDescribe(scope.row.id)"></i>
                        </template>
                     </el-table-column>
                  </el-table>
                  <el-row class="block">
                     <el-pagination
                             small
                             :page-size="desLimit"
                             @current-change="changeDesPage"
                             layout="prev, pager, next"
                             :total="desTotal">
                     </el-pagination>
                  </el-row>
               </el-col>
               <el-col :span="13">
                  <el-row class="el-form-item">
                     <h5><b class="needful">*</b>标准问题</h5>
                     <el-input class="width-200" placeholder="请输入标准问题" v-model="text"></el-input>
                  </el-row>
                  <el-row class="el-form-item">
                     <h5>问题状态</h5>
                     <el-switch v-model="state" :active-value="true" :inactive-value="false"></el-switch>
                  </el-row>
                  <el-row class="el-form-item">
                     <h5>问题类型</h5>
                     <el-radio-group v-model="type" @change="changeProblemType">
                        <el-radio label="QA">问答</el-radio>
                        <el-radio label="BUY">订购</el-radio>
                        <el-radio label="ROOMSERVICE">客房维护</el-radio>
                        <el-radio label="OTHER">其他</el-radio>
                     </el-radio-group>
                  </el-row>
                  <el-row class="el-form-item" v-show="type === 'ROOMSERVICE' && code !== '' ? true : false">
                     <h5><b class="needful">*</b>所属工单分类</h5>
                     <el-select v-model="workTypeId" placeholder="请选择" >
                        <el-option
                                v-for="item in workTypes"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                        </el-option>
                     </el-select>
                  </el-row>
                  <el-row class="el-form-item" style="margin-bottom: 10px">
                     <h5>说法规则</h5>
                     <p>
                        <span @click="showRegular = true">使用正则</span>
                        &nbsp;|&nbsp;
                        <span @click="showSemanticSlot = true">引用语义槽</span>
                     </p>
                     <el-input  placeholder="请输入内容" v-model="similarItem">
                        <el-button slot="append" @click="appendRegular" icon="el-icon-plus"></el-button>
                     </el-input>
                  </el-row>
                  <el-row class="el-form-item regular-list">
                     <el-row v-for="(item, index) of similar" :key="index">
                        <el-col :span="20">{{item}}</el-col>
                        <el-col :span="4">
                           <el-button type="text"  icon="el-icon-delete" @click="delRegular(index)"></el-button>
                        </el-col>
                     </el-row>
                  </el-row>
                  <el-row class="el-form-item">
                     <h5><b class="needful">*</b>答案</h5>
                     <el-select class="m-bottom-10" v-model="answerType" placeholder="请选择">
                        <el-option
                                v-for="item in answerTypes"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                        </el-option>
                     </el-select>
                     <!-- 问答 -->
                     <!-- 文本 -->
                     <el-row v-if="answerType === 'TEXT'">
                        <el-input type="textarea" v-model="answerDTOtext"></el-input>
                     </el-row>
                     <!-- 图片 -->
                     <el-row v-if="answerType === 'IMG'" class="flex-pic">
                        <img width="148" height="148" class="old-pic" alt="" :src="images">
                        <el-upload
                                ref="upload"
                                class="m-left-10 m-right-10"
                                :limit="1"
                                :show-file-list="true"
                                action="#"
                                :auto-upload="false"
                                list-type="picture-card"
                                :on-change="handleChange"
                                :on-preview="handlePictureCardPreview">
                           <i class="el-icon-plus"></i>
                        </el-upload>
                        <el-dialog :visible.sync="dialogVisible">
                           <img width="100%" :src="dialogImageUrl" alt="">
                        </el-dialog>
                     </el-row>
                     <!-- 图文消息 -->
                     <el-row v-if="answerType === 'IMGTEXT'" style="font-size: 13px">
                        <el-row class="m-top-15">
                           <p><b class="needful">*</b>标题</p>
                           <el-input  placeholder="请输入答案标题" v-model="imgText.title"></el-input>
                        </el-row>
                        <el-row class="m-top-15">
                           <p><b class="needful">*</b>描述</p>
                           <el-input  placeholder="请输入答案描述" v-model="imgText.detail"></el-input>
                        </el-row>
                        <el-row class="m-top-15">
                           <p>链接地址</p>
                           <el-input  placeholder="请输入链接地址" v-model="imgText.url"></el-input>
                        </el-row>
                        <el-row class="m-top-15 flex-pic" v-if="isRefresh">
                           <img width="148" height="148" class="old-pic" v-if="imgText.image" :src="imgText.image" alt="">
                           <el-upload
                                   ref="upload"
                                   class="m-left-10 m-right-10"
                                   :limit="1"
                                   :show-file-list="true"
                                   action="#"
                                   :auto-upload="false"
                                   list-type="picture-card"
                                   :on-change="handleChange"
                                   :on-preview="handlePictureCardPreview">
                              <i class="el-icon-plus"></i>
                           </el-upload>
                           <el-dialog :visible.sync="dialogVisible">
                              <img width="100%" :src="dialogImageUrl" alt="">
                           </el-dialog>
                        </el-row>
                     </el-row>
                     <!-- 富文本 -->
                     <el-row v-if="answerType === 'WEB'">
                        <vue-tinymce v-model="content" :setting="setting" />
                     </el-row>
                  </el-row>
                  <el-row class="el-form-item">
                     <el-button  v-if="categoryId" type="primary" @click="saveDescribe">保存</el-button>
                  </el-row>
               </el-col>
            </el-row>
         </el-col>
      </el-row>
      <!-- 正则匹配弹窗 -->
      <el-form label-width="105px">
         <el-dialog title="说法规则可用的正则符" width="610px" :visible.sync="showRegular">
            <el-row class="regular-explain">
               以下正则符号可在编写说法规则时使用，点击“插入”，即可将符号插入到当前说法输入框中。
               注意：使用正则的单条说法规则，展开后不得大于4千条，否则将不能保存。
            </el-row>
            <el-table :data="regularData" style="width: 100%">
               <el-table-column prop="symbol" label="符号" width="60"></el-table-column>
               <el-table-column prop="explain" label="说明"></el-table-column>
               <el-table-column prop="example" label="举例"></el-table-column>
               <el-table-column label="操作" width="80">
                  <template slot-scope="scope">
                     <el-button type="text" @click="insertSymbol(scope.row.symbol)">插入</el-button>
                  </template>
               </el-table-column>
            </el-table>
         </el-dialog>
      </el-form>
      <!-- 语义槽弹窗 -->
      <el-form label-width="105px">
         <el-dialog title="当前任务下得语义槽" width="610px" :visible.sync="showSemanticSlot">
            <el-row class="regular-explain">
               以下语义槽可在编写说法规则时引用，点击“引用”，即可将对应语义槽引用到当前说法输入框中。管理语义槽
            </el-row>
            <el-table :data="semanticSlotData" style="width: 100%">
               <el-table-column prop="name" label="语义槽名称" width="180"></el-table-column>
               <el-table-column prop="thesaurus" label="关联词库"></el-table-column>
               <el-table-column prop="quote" label="操作" width="80"></el-table-column>
            </el-table>
            <el-row class="regular-explain">
               1个语义槽，共1页
            </el-row>
         </el-dialog>
      </el-form>
   </section>
</template>

<script>
   import { mapState } from 'vuex'
   import { urlObj , OMS } from '@/api/interface'
   import {hotelMan, smartFaq} from "@/api/interface/oms";
   let id = 1000;
   export default {
      data(){
         let _this = this
         return{
            maps: new Map(),          // 存储当前子节点数据
            hotelName: '',            // 酒店名称
            name: '',                 // 分类名称
            isInput: false,           // 是否显示输入框
            id: '',                   // 分类ID
            newChild: [],             // 临时子节点列表
            treeData: [],             // 问题分类列表
            desData: [],              // 问题描述列表
            desTotal: 0,              // 问题描述列表总数
            desLimit: 10,             // 问题描述每页数
            desPage: 0,               // 问题描述当前页
            paramData: '',            // 问题描述查询参数
            showPlus: false,          // 显示添加按钮
            showRegular: false,       // 显示正则弹窗
            showSemanticSlot: false,  // 显示语义槽
            index: 1,
            defaultProps: {
               label: 'name',
               children: 'children',
               isLeaf: 'leaf'
            },
            regularData: [
               { symbol: '()', explain: '必选符号，表示小括号内的内容必须有', example: '#日期#天气(怎样|怎么样)' },
               { symbol: '[]', explain: '可选符号，表示中括号内的内容可有可无', example: '[请问|问一下]#日期#的天气' },
               { symbol: '(a|b)', explain: '表示多个取其一（仅限于出现在括号内）', example: '天气(如何|好吗|怎样)' },
               { symbol: '^', explain: '前停止符，表示说法不能向前扩展', example: '^播放歌曲' },
               { symbol: '$', explain: '后停止符，表示说法不能后扩展', example: '提高音量$' },
               { symbol: '{n}', explain: '重复符号，表示前面的元素重复n遍', example: '导航{2}#目的地#' },
               { symbol: '{n,m}', explain: '重复符号，表示前面的元素重复n～m遍', example: '我要{1,2}导航#目的地#' }
            ],
            semanticSlotData: [
               { name: '设施名称', thesaurus: '设施名称', quote: '引用' }
            ],
            answerTypes: [
               { label: '文本', value: 'TEXT' },
               { label: '图片', value: 'IMG' },
               { label: '图文消息', value: 'IMGTEXT' },
               { label: '富文本', value: 'WEB' }
            ],
            answerType: 'TEXT',       // 答案类型
            type: 'QA',               // 问题类型
            state: true,              // 问题状态
            text: '',
            imgText: {
               title: '',             // 图文标题
               url: '',               // 图文链接
               detail: '',            // 图文详情
            },                        // 标准问题
            images: '',               // 图片
            file: null,               // 图片资源
            categoryId: '',           // 问题分类
            similar: [],              // 说法正则
            similarItem: '',          // 说法正则
            questionId: '',           // 问题ID
            answerId: '',             // 答案ID
            answerDTOtext: '',        // 文本类型
            workTypes: [],            // 工单分类列表
            workTypeId: '',           // 工单分类ID
            code: '',                 // 问题分类类型
            /* 图片 */
            dialogImageUrl: '',       // 放大图地址
            dialogVisible: false,     // 放大图显示/隐藏
            isRefresh: true,          // 是否刷新组件
            /* 富文本 */
            content: '',
            setting: {
               menubar: false,
               toolbar: "undo redo | code | fullscreen | formatselect alignleft aligncenter alignright alignjustify | link unlink | numlist bullist | image media table | fontselect fontsizeselect forecolor backcolor | bold italic underline strikethrough | indent outdent | superscript subscript | removeformat |",
               toolbar_drawer: "sliding",
               quickbars_selection_toolbar: "removeformat | bold italic underline strikethrough | fontsizeselect forecolor backcolor",
               plugins: "link image table lists fullscreen quickbars code",
               language: 'zh_CN',
               height: 350,
               images_upload_handler: function (blobInfo, succFun, failFun) {
                  const url = urlObj.upload
                  var file = blobInfo.blob();//转化为易于理解的file对象
                  let param = new FormData()
                  param.append('file', file)
                  param.append('module', 'faq')
                  param.append('menu', 'manageQA')
                  param.append('func', 'image')
                  param.append('category', 'image')
                  param.append('isThumb', true)
                  if (_this.bizId) {
                     param.append('bizId', _this.bizId)
                  }else {
                     param.append('origin', 'GBIZID')
                  }
                  this.$axios.post(url, param, 'file').then(res => {
                     if (res.success){
                        let data = res.records
                        let imgUrl = window.getResourcesHost() + data.location + '/' + data.formatName
                        _this.bizId = data.bizId
                        succFun(imgUrl)
                     }
                  })
               }
            },
            /* 提示文本 */
            hotelId_no_null: '请选择酒店！',
            parentId_no_null: '请选择词库！',
            wordName_no_null: '词条值不能为空！',
            add_success: "添加成功！",
            del_success: "删除成功！",
            update_success: "修改成功！",
            confirm: '确定',
            cancel: '取消',
            confirm_remove: '确定移除？',
            prompt: '提示！',
            release_success: '发布成功！'
         }
      },
      computed: {
         ...mapState(['hotelInfo'])
      },
      methods: {
         // 获取问题分类子列表
         getCategories(node, resolve){
            // 将当前子节点数据存储到map中
            this.maps.set(node.data.id, { node, resolve })
            const url = smartFaq.questionCategories
            let param = { level: 1 }
            if (node.level === 1){
               param.parentId = node.data.id
               delete param.level
            }
            this.$axios.post(url, param).then(res => {
               if (res.success) resolve(res.records)
            })
         },
         // 重新获取当前分类子列表
         refreshLoadTree(parentId){
            // 根据父级id取出对应节点数据
            if (this.maps.get(parentId)){
               const { node, resolve } = this.maps.get(parentId)
               this.getCategories(node, resolve)
            }
         },
         // 改变选中问题分类
         changeCategory(row){
            if (!this.hotelInfo.id) return this.$message.error('请选中酒店！')
            this.code = row.code || ''
            this.categoryId = row.id
            this.getDescribes()
         },
         // 保存问题分类
         saveCategory(action = 'add'){
            // 提交验证
            if (!this.name) {
               this.$message.error('问题分类不能为空！')
               return;
            }
            let url = smartFaq.addQuestionCategory
            let param = {
               name: this.name,
               parentId: this.categoryId,
            }
            if (action === 'edit'){
               url = smartFaq.updateQuestionCategory
               param.id = this.categoryId
               delete param.parentId
            }
            this.$axios.post(url, param).then(res => {
               if (res.success){
                  this.isInput = false
                  this.$message({
                     message: action === 'add' ? '添加成功！' : '编辑成功！',
                     type: 'success'
                  })
                  this.refreshLoadTree(this.categoryId) // 刷新当前子节点列表
               }
            })
         },
         // 删除问题分类
         delCategory(node, data) {
            this.$confirm(this.confirm_remove, this.prompt, {
               confirmButtonText: this.confirm,
               cancelButtonText: this.cancel,
               type: 'warning'
            }).then(() => {
               const url = smartFaq.delQuestionCategory
               const param = { ids: data.id }
               this.$axios.post(url, param).then(res => {
                  if (res.success){
                     this.refreshLoadTree(data.parentId) // 刷新当前子节点列表
                     this.$message({ message: '删除成功！', type: 'success' })
                  }
               })
            })
         },
         /* 问题描述 */
         // 改变当前问题类型
         changeProblemType(type){
            if (type === 'ROOMSERVICE' && this.workTypes.length === 0){
               this.getWorkOrderCategories()
            }
         },
         // 获取工单分类列表
         getWorkOrderCategories(){
            const url = hotelMan.workOrderCategory
            const param = { type: 1 }
            this.$axios.post(url, param).then(res => {
               if (res.success){
                  let workTypes = res.records
                  this.workTypes = workTypes.map(item => {
                     return { value: item.typeCode,label: item.typeName }
                  })
               }
            })
         },
         // 插入正则符号
         insertSymbol(symbol, event){
            this.similarItem = this.similarItem + symbol

            /*const editEl = event.target;
            // return console.log(editEl);
            if (editEl.selectionStart ||editEl.selectionStart === 0) {
               // 非IE浏览器
               this.cursorPosition = editEl.selectionStart;
            } else {
               // IE
               const range = document.selection.createRange();
               range.moveStart('character', -editEl.value.length);
               this.cursorPosition = range.text.length;
            }
            console.log(this.cursorPosition);*/
         },
         // 添加正则规则
         appendRegular(){
            if (this.similarItem){
               this.similar.push(this.similarItem)
               this.similarItem = ''
            }
         },
         // 删除正则规则
         delRegular(index){
            this.similar.splice(index,1)
         },
         // 获取问题描述列表
         getDescribes(){
            const url = smartFaq.questionDescribes
            const param = {
               categoryId: this.categoryId,
               paramData: this.paramData,
               hotelId: this.hotelInfo.id,
               limit: this.desLimit,
               page: this.desPage
            }
            this.$axios.post(url, param).then(res => {
               if (res.success){
                  this.showPlus = true
                  this.desTotal = res.total
                  this.desData = res.records
               }
            })
         },
         // 改变当前问题描述页
         changeDesPage(num){
            this.desPage = num
            this.getDescribes()
         },
         // 删除问题描述
         delDescribe(id){
            const url = smartFaq.delQuestionDescribe
            const param = { ids: id }
            this.$axios.post(url, param).then(res => {
               if (res.success) {
                  this.getDescribes()
                  this.$message({ message: '删除成功！', type: 'success' })
               }
            })
         },
         // 获取上传的文件资源
         handleChange(file){
            // 验证文件格式、大小
            const imgFormat = file.raw.type === 'image/jpeg' || file.raw.type === 'image/png' || file.raw.type === 'image/gif';
            const isLt2M = file.size / 1024 / 1024 < 2;
            if (!imgFormat) {
               this.$message.error('上传LOGO图片只能是JPG/PNG/GIF格式!');
               // 强制刷新局部DOM节点
               this.isRefresh = false
               this.$nextTick(() => {
                  this.isRefresh = true
               })
               return
            }
            if (!isLt2M) {
               this.$message.error('上传LOGO图片大小不能超过 2MB!');
               this.isRefresh = false
               this.$nextTick(() => {
                  this.isRefresh = true
               })
               return
            }
            this.file = file.raw
         },
         // 点击图片查看大图
         handlePictureCardPreview(file){
            this.dialogImageUrl = file.url;
            this.dialogVisible = true;
         },
         // 编辑问题描述
         updateDescribe(row){
            if (row){
               this.similarItem = ''
               this.state = row.state
               this.similar = row.similar.split(',')
               this.text = row.text
               this.type = row.type
               this.questionId = row.id
               this.workTypeId = row.workTypeId
               this.getAnswerList()
               if (row.type === 'ROOMSERVICE' && this.workTypes.length === 0){
                  this.getWorkOrderCategories()
               }
            }
         },
         // 添加问题描述
         appendDescribe(){
            this.questionId = ''
            this.similar = []
            this.text = ''
            this.type = 'QA'
            this.state = true
            this.answerType = 'TEXT'
            this.answerDTOtext = ''
            this.imgText.title = ''
            this.imgText.url = ''
            this.imgText.detail = ''
            this.$refs.desTable.setCurrentRow()
         },
         // 保存问题描述
         saveDescribe(){
            // 表单验证
            if (!this.text) {
               this.$alert('标准问题不能为空！', '', {
                  confirmButtonText: '确定',
                  type: 'error'
               })
               return
            }
            // 文本
            if (this.answerType === 'TEXT'){
               if (!this.answerDTOtext){
                  this.$alert('文本答案不能为空！', '', {
                     confirmButtonText: '确定',
                     type: 'error'
                  })
                  return
               }
            }
            // 图片
            if (this.answerType === 'IMG'){
               if (!this.file){
                  this.$alert('图片答案不能为空！', '', {
                     confirmButtonText: '确定',
                     type: 'error'
                  })
                  return
               }
            }
            // 图文
            if (this.answerType === 'IMGTEXT'){
               if (!this.imgText.title){
                  this.$alert('图文答案标题不能为空！', '', {
                     confirmButtonText: '确定',
                     type: 'error'
                  })
                  return
               }
               if (!this.imgText.detail){
                  this.$alert('图文答案描述不能为空！', '', {
                     confirmButtonText: '确定',
                     type: 'error'
                  })
                  return
               }
            }
            // 工单分类不能为空
            if (this.type === 'ROOMSERVICE'){
               if (!this.workTypeId){
                  this.$alert('工单分类不能为空！', '', {
                     confirmButtonText: '确定',
                     type: 'error'
                  })
                  return
               }
            }

            let url = smartFaq.addQuestionDescribe
            let param = new FormData()
            // 文本类型
            if (this.answerType === 'TEXT'){
               param.append('answerDTO.text', this.answerDTOtext)
            }
            // 图片类型
            if (this.answerType === 'IMG'){
               if (this.file){
                  param.append('file', this.file)           // 文件
                  param.append('module', 'faq')       // 所在模块
                  param.append('menu', 'manageQA')    // 所属菜单
                  param.append('func', 'image')       // 图片功能
                  param.append('category', 'image')   // 图片分类
                  param.append('isThumb', true)       // 是否开启缩略图
               }
               param.append('answerDTO.text', 'IMG')
            }
            // 图文类型
            if (this.answerType === 'IMGTEXT'){
               if (this.file){
                  param.append('file', this.file)           // 文件
                  param.append('module', 'faq')       // 所在模块
                  param.append('menu', 'manageQA')    // 所属菜单
                  param.append('func', 'image')       // 图片功能
                  param.append('category', 'image')   // 图片分类
                  param.append('isThumb', true)       // 是否开启缩略图
               }
               param.append('answerDTO.text', JSON.stringify(this.imgText))
            }
            // 富文本类型
            if (this.answerType === 'WEB'){
               if (this.bizId) param.append('bizId', this.bizId)
               param.append('answerDTO.text', this.content)
            }
            param.append('answerDTO.answerType', this.answerType)
            param.append('answerDTO.hotelId', this.hotelInfo.id)
            param.append('text', this.text)
            param.append('state', this.state)
            param.append('type', this.type)
            param.append('workTypeId', this.workTypeId)
            param.append('hotelId', this.hotelInfo.id)
            param.append('similar', this.similar.toString())
            param.append('categoryId', this.categoryId)
            // 编辑操作
            if (this.questionId){
               url = smartFaq.editQuestionDescribe
               param.append('id', this.questionId)
               param.append('answerDTO.id', this.answerId)
            }
            this.$axios.post(url, param, 'file').then(res => {
               if (res.success){
                  this.getDescribes()
                  this.appendDescribe()
                  this.$message({ showClose: true, message: '添加成功！', type: 'success' })
               }
            })
         },
         // 获取答案列表
         getAnswerList(){
            const url = smartFaq.answerList
            const param = { hotelId: this.hotelInfo.id, questionId: this.questionId }
            this.$axios.post(url, param).then(res => {
               if (res.success) {
                  let answerList = res.records
                  if (answerList.length === 0) {
                     this.answerId = ''
                     this.answerDTOtext = ''
                     this.content = ''
                     this.images = ''
                     this.imgText.title = ''
                     this.imgText.url = ''
                     this.imgText.detail = ''
                     this.imgText.image = ''
                     return
                  }
                  this.answerId = answerList[0].id
                  this.answerType = answerList[0].answerType
                  if (this.answerType === 'IMGTEXT'){
                     this.imgText = JSON.parse(answerList[0].text)
                     if (this.imgText.image) this.imgText.image = window.getResourcesHost() + this.imgText.image
                  }
                  if (this.answerType === 'TEXT'){
                     this.answerDTOtext = answerList[0].text
                  }
                  if (this.answerType === 'IMG'){
                     let images = JSON.parse(answerList[0].text)[0].images
                     if (images) this.images = window.getResourcesHost() + images
                  }
                  if (this.answerType === 'WEB'){
                     this.content = answerList[0].text
                  }
               }
            })
         },
      }
   }
</script>

<style scoped>
   .cont .top-title{
      line-height: 40px;
      font-size: 16px;
      background: #f2f2f2;
      color: #666666;
      padding: 0 10px;
      font-weight: bold;
      border: solid 1px #EBEEF5;
   }
   .cont .top-title i{
      font-weight: bold;
      font-size: 16px;
      float: right;
      line-height: 40px;
   }
   .cont .cont-main{
      padding: 50px;
      background: #ffffff;
   }
   .btm-tree .input-box{
      padding: 10px;
   }
   .custom-tree-node {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 14px;
      padding-right: 8px;
   }
   .plus-btn{
      width: 120px;
      margin-right: 10px;
      border-style: dashed;
   }
   .el-form-item:nth-child(1) h5{
      margin-top:0;
   }
   .el-form-item h5{
      font-size: 16px;
      margin-bottom: 16px;
      margin-top:16px;
   }
   .el-form-item p{
      margin-bottom: 6px;
   }
   .el-form-item span{
      font-size: 13px;
      color: #409eff;
   }
   .regular-explain{
      line-height: 24px;
      color: #999999;
      margin: 10px 0;
   }
   .regular-list{
      font-size: 13px;
      color: #999999;
   }
   .regular-list div{
      line-height: 34px;
      border-bottom: 1px #f1f1f1 dashed;
   }
   .des-header{
      display: flex;
   }
   /deep/ .el-tree--highlight-current .el-tree-node.is-current > .el-tree-node__content{
      background: #ecf5ff;
      color: #409eff;
   }
   .flex-pic{
      display: flex;
   }

   /deep/ .el-table__empty-block{
      border-left:1px solid #EEEEEE;
      border-right:1px solid #EEEEEE;
      width:568px !important;
   }
   /deep/ .el-tree-node__content{
      margin-top:8px;
   }
</style>
